import { navigate, Link } from "gatsby";
import React, { useState, useRef } from "react";
import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import WelcomeHeader from "../../../components/welcome_header";
import ClipboardImg from "../../../../img/copy_to_clipboard.png";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";

const InviteMembersPage = () => {
  const [greeting, setGreeting] = useState("Dear Friends,");
  const [loading, setLoading] = useState("");
  const realmApp = useRealmApp();

  const [inviteLink, setInviteLink] = useState("loading...");
  const [startDateStr, setStartDateStr] = useState("[Insert Date Here]");
  const [customMessage, setCustomMessage] = useState("Custom message...");

  const defaultParagraphRef = useRef(null);
  const customParagraphRef = useRef(null);

  const copyToClipboard = () => {
    console.log(customParagraphRef.current.innerHTML);
    let customMessageCopy = `${defaultParagraphRef.current.innerText} ${customParagraphRef.current.innerHTML}`;
    if (customParagraphRef.current.innerHTML === "Custom message...") {
      navigator.clipboard.writeText(defaultParagraphRef.current.innerText).then(
        (success) => console.log("text copied"),
        (err) => console.log("error copying text")
      );
    } else {
      navigator.clipboard.writeText(customMessageCopy).then(
        (success) => console.log("text copied"),
        (err) => console.log("error copying text")
      );
    }
  };

  async function invitationDone() {
    if (!Boolean(realmApp.app.currentUser?.customData.invited_members)) {
      setLoading("working...");

      console.log("setting invited_members true");
      await realmApp.setMongoUserAttribute({ invited_members: true });
    }
    navigate("/facilitator/dashboard/");
  }

  async function fetchInviteLink() {
    let circle_with_code = await realmApp.fetchCircle();

    if (circle_with_code != null) {
      setInviteLink(
        process.env.GATSBY_URL +
          "/member/signup/?invite_code=" +
          circle_with_code.invite_code
      );
    } else {
      setInviteLink(
        "error loading your circle's invite code. Please contact MentorAmp support."
      );
    }
  }

  React.useEffect(() => {
    fetchInviteLink();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WelcomeHeader link={true} suffix="Facilitator Portal" />
      <div style={{ maxWidth: "500px" }}>
        <div>
          <div>Invite your members to MentorAmp!</div>
          <br />
          <div style={{ backgroundColor: "#bebebe", padding: "10px" }}>
            <input
              type="text"
              value={greeting}
              onChange={(e) => setGreeting(e.target.value)}
            />
            <img
              style={{ height: "20px", float: "right", cursor: "pointer" }}
              src={ClipboardImg}
              // TODO on hover display: "copy message to clipboard"
              // TODO on copy display message buddle "message copied to clipboard"
              onClick={copyToClipboard}
            />
            <br />
            <p ref={defaultParagraphRef} className="copy-text">
              You're invited to join our circle on MentorAmp, the peer
              mentorship platform! Please sign up for MentorAmp by clicking this
              link:
              <br />
              <a href={inviteLink}>{inviteLink}</a>
              <br />
              Mentoramp starts {startDateStr}, so please create your account
              before then if you would like to participate in the program.
            </p>
            <textarea
              style={{ width: "100%", height: "200px" }}
              value={customMessage}
              onChange={(e) => setCustomMessage(e.target.value)}
              ref={customParagraphRef}
            />
          </div>
        </div>
        <br />
        <div
          style={{
            width: "75%",
            maxWidth: "400px",
            display: "flex",
            justifyContent: "space-around",
            float: "right",
          }}
        >
          <button
            style={{ width: "50%" }}
            onClick={() => {
              navigate("/facilitator/dashboard/");
            }}
          >
            back
          </button>
          <div style={{ width: "50%" }} />
          <button style={{ width: "100%" }} onClick={invitationDone}>
            I invited my members!
          </button>
        </div>
      </div>
      {loading}
    </div>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <InviteMembersPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
